// Taken but modified from https://nuxt.com/docs/guide/recipes/custom-usefetch
import { FetchError } from "ofetch"
import { captureException, setContext } from "@sentry/vue"
export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig()

  const fetcher = $fetch.create({
    baseURL: runtimeConfig.public.apiBase,
    credentials: import.meta.client ? "include" : undefined,
    onRequestError: ({ error }) => {
      captureException(error)
    },
    async onRequest({ options }) {
      const csrf = useCookie("XSRF-TOKEN")

      const { token } = useAuth()
      if (import.meta.server) {
        // Cloudflare workers crashes if this is set on server side
        // See https://github.com/cloudflare/workers-sdk/issues/2514
        options.credentials = undefined
      }
      const headers = (options.headers ||= {})
      if (Array.isArray(headers)) {
        token.value && headers.push(["Authorization", `Bearer ${token.value}`])
        csrf.value && headers.push(["X-XSRF-TOKEN", csrf.value])
        if (
          options.method != "get" &&
          !headers.some((header) => header[0].toLowerCase() === "accept")
        ) {
          headers.push(["Accept", "application/json"])
        }
      } else if (headers instanceof Headers) {
        token.value && headers.set("Authorization", `Bearer ${token.value}`)
        csrf.value && headers.set("X-XSRF-TOKEN", csrf.value)
        if (options.method != "get" && !headers.has("Accept")) {
          headers.set("Accept", "application/json")
        }
      } else {
        token.value && (headers.Authorization = `Bearer ${token.value}`)
        csrf.value && (headers["X-XSRF-TOKEN"] = csrf.value)
        if (options.method != "get" && !headers.Accept) {
          headers.Accept = "application/json"
        }
      }
    },
    async onResponseError(context) {
      const ignoredSentryErrorCodes = [
        419, // CSRF Token expired or invalid. If this happens we'll just try again
        429, // Rate limit. We'll get that sometimes on the `points` endpoint, but we can ignore the error
      ]
      const response = context.response
      const { logout } = useAuth()
      if (response.status === 401) {
        await logout()
      } else if (
        response.redirected &&
        response.headers.get("location")?.includes("/login")
      ) {
        await logout()
        // 429 is rate limit.
      } else if (!ignoredSentryErrorCodes.includes(response.status)) {
        setContext("error_context", context)
        setContext("error_response", response)
        setContext("status_code", { code: response.status })
        const error = context.error
        if (!error) {
          captureException(new Error(`${response.status}: Unknown fetch error`))
        } else {
          captureException(error)
        }
      }
    },
  })

  /** Fetches with authentication, proper csrf token and accept headers.
   * Retries after getting CSRF error.
   * The API usually works without an accept header, but if the token is invalid it will return a 302 instead of 401. */
  const api = async function api<T>(...a: Parameters<typeof fetcher<T>>) {
    try {
      return await fetcher<T>(...a)
    } catch (e) {
      const isCsrfError = e instanceof FetchError && e?.response?.status == 419
      if (isCsrfError) {
        await fetcher(`/auth/csrf`, { credentials: "include" })
        return await fetcher<T>(...a)
      }
      throw e
    }
  } as typeof $fetch

  // The `$fetch` option for `useFetch` is typed as `$Fetch`/`typeof $fetch`
  // If we don't set these, the types won't be correct.
  // ofetch does the same thing: https://github.com/unjs/ofetch/blob/9cf16e098e8ebfe3e353e274b4bc676d2d39f845/src/fetch.ts#L239
  api.raw = fetcher.raw
  api.create = fetcher.create

  return {
    provide: {
      api,
    },
  }
})
