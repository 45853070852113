export default defineNuxtRouteMiddleware(async (to) => {
  const code = to.query["code"]?.toString()
  const state = to.query["state"]?.toString()

  const { token, authenticated, session, fetchUser } = useAuth()

  if (code && state && !to.path.startsWith("/sso/")) {
    // if both code and state is in url, we're dealing with SSO.
    // if we're already on /sso/ we shouldn't redirect
    const redirectUrl = `/sso/microsoft?${new URLSearchParams({ code, state }).toString()}`
    return navigateTo(redirectUrl)
  } else if (state && !code) {
    // Magic links
    // if only `state` is in the url we can use this as a token.
    token.value = state

    // Remove redirect from query to avoid infinite redirects
    // Remove state from query so we don't hit this code branch again
    // Query might still contain locale after this and we don't want to remove that
    const query = { ...to.query, state: undefined, redirect: undefined }
    if (to.query.redirect) {
      return navigateTo(
        { path: to.query.redirect.toString(), query },
        { external: true },
      )
    } else {
      return navigateTo({ path: "/", query }, { external: true })
    }
  }

  const unauthenticatedRoutes = ["login", "login-link", "logout"]
  if (
    unauthenticatedRoutes.includes(to.name?.toString() ?? "") ||
    to.query.code
  ) {
    return
  }

  if (!authenticated.value) {
    return navigateTo("/logout")
  }
  if (!session.value) {
    try {
      await fetchUser()
    } catch {
      return navigateTo("/logout")
    }
  }
})
