import type { IFetchError } from "ofetch"
function isFetchError(
  obj: unknown,
): obj is { data: IFetchError<{ message: string }> } {
  return (
    (obj as { data?: IFetchError<{ message: string }> })?.data != undefined &&
    (obj as { data?: IFetchError<{ message: string }> })?.data?.message !=
      undefined
  )
}
export function useLocalisedError(_error: unknown) {
  const { t, te } = useI18n()
  return computed(() => {
    const error = unref(_error)
    if ((error ?? undefined) == undefined) {
      return undefined
    }
    if (isFetchError(error)) {
      if (error.data.status == 419) {
        return t("dynamic_keys.api.errors.csrf")
      }
      return te(`dynamic_keys.${error.data.message}`)
        ? t(`dynamic_keys.${error.data.message}`)
        : t("dynamic_keys.api.messages.csrf")
    }

    return t("dynamic_keys.api.errors.unknown")
  })
}
