<template>
  <IconBase>
    <g fill="currentColor" stroke="none">
      <g fill="none" fill-rule="evenodd"
        ><path
          d="M19.325 8.797 1.925.167A1.51 1.51 0 0 0 1.244 0C.563 0 .006.469.006 1.042H0v17.916h.006C.006 19.531.562 20 1.244 20c.256 0 .468-.073.7-.177l17.381-8.62c.413-.286.675-.719.675-1.203 0-.484-.262-.911-.675-1.203Z"
          fill="CurrentColor"
          fill-rule="nonzero"
      /></g>
    </g>
  </IconBase>
</template>
