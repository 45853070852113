import revive_payload_client_sLuXSIoPyi from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_zxkw53mypqp26d6loylvjp7yu4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_PWaLz1O4Bp from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_zxkw53mypqp26d6loylvjp7yu4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jIH8emqPWs from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_zxkw53mypqp26d6loylvjp7yu4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ZsjbrzPUUQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_zxkw53mypqp26d6loylvjp7yu4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_AXA2GlJakJ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_zxkw53mypqp26d6loylvjp7yu4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_d6ZDxeAbB3 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_zxkw53mypqp26d6loylvjp7yu4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_CoWoqlnRWl from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_zxkw53mypqp26d6loylvjp7yu4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/app/hub/.nuxt/components.plugin.mjs";
import prefetch_client_STFBUXbMWH from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_zxkw53mypqp26d6loylvjp7yu4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_IZpkGLpny4 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.21.3_vue@3.5.6_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_KTuvZESIYj from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.21.3_vue@3.5.6_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import amplitude_qcoZpE5aTU from "/opt/buildhome/repo/app/hub/plugins/amplitude.ts";
import api_GFfDXud5Cr from "/opt/buildhome/repo/app/hub/plugins/api.ts";
import bugsnagReporter_sNiTWE5mo8 from "/opt/buildhome/repo/app/hub/plugins/bugsnagReporter.ts";
import sentry_client_shVUlIjFLk from "/opt/buildhome/repo/app/hub/plugins/sentry.client.ts";
export default [
  revive_payload_client_sLuXSIoPyi,
  unhead_PWaLz1O4Bp,
  router_jIH8emqPWs,
  payload_client_ZsjbrzPUUQ,
  navigation_repaint_client_AXA2GlJakJ,
  check_outdated_build_client_d6ZDxeAbB3,
  chunk_reload_client_CoWoqlnRWl,
  components_plugin_KR1HBZs4kY,
  prefetch_client_STFBUXbMWH,
  switch_locale_path_ssr_IZpkGLpny4,
  i18n_KTuvZESIYj,
  amplitude_qcoZpE5aTU,
  api_GFfDXud5Cr,
  bugsnagReporter_sNiTWE5mo8,
  sentry_client_shVUlIjFLk
]