<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    variant?: "default" | "success" | "warning" | "error" | "primary"
    size?: "sm" | "default"
  }>(),
  { variant: "default", size: "default" },
)
const badgeClass = computed(() => {
  if (props.variant === "success") {
    return "bg-emerald-50/50 text-success-dark ring-emerald-500/20"
  }

  if (props.variant === "warning") {
    return "bg-yellow-50/50 text-warning-dark ring-yellow-500/20"
  }

  if (props.variant === "error") {
    return "bg-rose-50/50 text-error-dark ring-rose-500/20"
  }

  if (props.variant === "primary") {
    return "bg-indigo-50/50 text-primary-dark ring-indigo-500/20"
  }

  return "bg-slate-50/50 text-slate-600 ring-slate-500/20"
})
</script>
<template>
  <span
    class="inline-flex items-center rounded-full px-2 py-1 ring-1 ring-inset"
    :class="[badgeClass, { 'text-xs': size == 'sm' }]"
    ><slot
  /></span>
</template>
